import axios from './index'

export default {
  getHospitalList() {
    return axios.get('/data/hospital')
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  submitNewBranchRoom(model) {
    return axios.post('/branchRoom/upsert', model)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getBranchRoom(roomId) {
    return axios.post('/branchRoom/data', { id: roomId })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getBranchRoomList(roomId) {
    return axios.get('/data/common/branchRoom', roomId)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  }
}
