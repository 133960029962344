<template>
  <b-container fluid>
    <ValidationObserver ref="branchRoomObserver" v-slot="{ invalid }">
      <form>
        <b-row>
          <b-form-group class="col-md-6" :label="$t('Name')" label-for="name">
            <ValidationProvider :name="$t('Name')" rules="required" v-slot="{ errors }">
              <b-form-input :class="(errors.length > 0 ? ' is-invalid' : '')" v-model="model.name " type="text"></b-form-input>
              <b-form-invalid-feedback>{{ $t('ValidationMessage.CannotBeEmpty') }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
          <b-form-group class="col-md-6" :label="$t('ShowInCalendar')" label-for="showInCalendar">
            <v-select v-model="model.showInCalendar"
                      :reduce="s => convertBool(s.id)" label="name"
                      :options="yesNoList">
            <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
          </b-form-group>
          <b-form-group class="col-md-6" :label="$t('CalendarColor')" label-for="calendarColor">
            <v-select v-model="model.calendarColor"
                      :reduce="s => s.id" label="name"
                      :options="colorList">
            <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
          </b-form-group>
          <b-form-group class="col-md-6" :label="$t('DisplayOrder')" label-for="displayOrder">
            <b-form-input class="form-control"
                          v-model="model.displayOrder" @keypress="$root.isNumber" />
          </b-form-group>
        </b-row>

        <!--<b-form-group label-cols-md="4" content-cols-md="2"
                      :label="$t('IsHospitalRoom')" label-for="isHospitalRoom">
          <b-form-checkbox v-model="model.isHospitalRoom" switch size="lg"></b-form-checkbox>
        </b-form-group>

        <b-row>
          <b-form-group class="col-md-12" v-if="model.isHospitalRoom"
                        :label="$t('Hospital')" label-for="hospital">
            <ValidationProvider :name="$t('Hospital')" rules="required" v-slot="{ errors }">
              <v-select v-model="model.hospitalID"
                        :reduce="s => s.id" label="name"
                        :options="hospitalList"
                        :class="(errors.length > 0 ? ' is-invalid' : '')"
                        required>
              <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
              <b-form-invalid-feedback> {{ $t('ValidationMessage.CannotBeEmpty') }} </b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
        </b-row>-->

        <modal-footer-button :set-is-submitting="isSubmitting"
                             @submit="submitNewBranchRoom"></modal-footer-button>

      </form>
    </ValidationObserver>
  </b-container>
</template>
<script>
  import commonServices from '../../../services/common'
  import brachRoomService from '../../../services/branchRoom'

  import { refreshSmartDuoData } from '../../../helpers/dataRefresher'
  import ModalFooterButton from '../../../components/shared/ModalFooterButton'
  export default {
    name: 'BranchRoom',
    components: {
      ModalFooterButton
    },
    props: {
      branchRoomId: String
    },
    data() {
      return {
        model: {
          id: '',
          name: '',
          calendarColor: '',
          displayOrder: null,
          showInCalendar: '',
          isHospitalRoom: false,
          hospitalID: ''

        },
        colorList: [],
        yesNoList: [],
        hospitalList: [],
        isSubmitting: false
      }
    },
    methods: {
      getTrueFalseList() {
        commonServices.getYesNoList().then((response) => {
          if (response) {
            this.yesNoList = response;
          }
        })
      },
      getColors() {
        commonServices.getColors().then((response) => {
          if (response) {
            this.colorList = response;
          }
        })
      },
      getHospitalList() {
        brachRoomService.getHospitalList().then((response) => {
          if (response) {
            this.hospitalList = response;
          }
        })
      },
      getBranchRoom: function () {
        if (this.model.id && this.model.id.length > 0) {
          brachRoomService.getBranchRoom(this.model.id).then(
            (result) => {
              if (result != null) {
                this.model = result;
              } else {
                this.$toastr.error(this.$t('Error'));
              }
            }
          )
        }
      },
      async submitNewBranchRoom() {
        const isValid = await this.$refs.branchRoomObserver.validate();
        if (!isValid || this.model.isHospitalRoom && (!this.model.hospitalID || this.model.hospitalID.length == 0)) {
          this.$toastr.error(this.$t('ValidationMessage.EmptyAndValidateError'));
          return;
        }

        this.isSubmitting = true;

        brachRoomService.submitNewBranchRoom(this.model).then((result) => {
          this.isSubmitting = false;
          if (result) {
            this.$emit('closeModal');
            this.$toastr.success(this.$t('Success'));
            refreshSmartDuoData();
          } else {
            this.$toastr.error(this.$t('Error'));
          }
        }).finally(() => { this.isSubmitting = false; });
      },
      convertBool(value) {
        return value == '1' ? true : false;
      },
    },
    watch: {
      'model.isHospitalRoom': function (value) {
        if (!value)
          this.model.hospitalID = '';
      }
    },
    mounted() {
      this.getTrueFalseList();
      this.getColors();
      this.getHospitalList();

      if (this.branchRoomId && this.branchRoomId.length > 0) {
        this.model.id = this.branchRoomId;
        this.getBranchRoom();
      }
    }
  }
</script>
